export function filterTableData(tableData, filterVal) {

  if (filterVal) {

    const filteredTableData = tableData.filter(function(tableRowData) {

      const tableRowDataAllStrVals = tableRowData.map(String),
            matches = tableRowDataAllStrVals.filter(s => s.includes(filterVal));

      return (matches.length > 0);

    });

    return filteredTableData;

  }
  else return tableData;

}

export function getPaginationNums(currentPage, pagesCount, delta){

  let result = [];

  if (pagesCount === 0) result = [];
  else if (pagesCount === 1) result = [1];
  else if (pagesCount === 2) result = [1,2];
  else {

    if (currentPage === 1) currentPage = 2;
    else if (currentPage === pagesCount) currentPage = pagesCount-1;

    let start = currentPage,
        end = currentPage;

    for (let i=0; i<delta; i++) {

      if (start > 2) start--;
      else if (end < (pagesCount-1)) end++;

      if (end < (pagesCount-1)) end++;
      else if (start > 2) start--;

    }

    result = Array.from({length: end-start+1}, (el, i) => start + i);

    if (result[0] === 2) result = [1].concat(result);
    else result = [1, '...'].concat(result);

    if (result[result.length-1] === (pagesCount-1)) result = result.concat([pagesCount]);
    else result = result.concat(['...', pagesCount]);

  }

  return result;

}