import React from 'react';

import { getPaginationNums } from '../functions/functions.js';

class Pagination extends React.Component {

  render() {

    const currentPage = this.props.currentPage,
          pagesCount = this.props.pagesCount,
          paginationNums = getPaginationNums(currentPage, pagesCount, 2);

    const pagination = paginationNums.map((paginationItem, index) => {

      let pagItemClassName = (paginationItem==='...') ? 'sep' : 'item';

      if (paginationItem === currentPage) pagItemClassName += ' current';

      return (
        <span
          key={index}
          className={pagItemClassName}
          onClick={() => this.props.onClick(paginationItem)}
        >
          {paginationItem}
        </span>
      );

    });

    return (
        <div className="pagination">
          {pagination}
        </div>
    );
  }

}

export default Pagination;