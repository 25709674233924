import React from 'react';

function TableHeadRow(props) {

  const tableHeadData = (props.pagesCount > 0) ? props.tableHeadData : [];

  const rowHeadCols = tableHeadData.map((headVal, index) => {

    let tableHeadClassName = 'table-head';

    if (props.currentSortColumnIndex === index) {

      tableHeadClassName += (props.currentSortDirection === 1) ? ' sort asc' : ' sort desc';

    }

    return (
      <th
        key={index}
        onClick={() => props.onClick(index)}
        className={tableHeadClassName}
      >
        {headVal}
      </th>
    );
  });

  return (
    <tr>
      {rowHeadCols}
    </tr>
  );

}

export default TableHeadRow;