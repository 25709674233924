import React from 'react';

import TableRow from './TableRow';
import Filter from './Filter';
import TableHeadRow from './TableHeadRow';
import NoFilterResultsMessage from './NoFilterResultsMessage';
import Pagination from './Pagination';

import { filterTableData } from '../functions/functions.js';

class TableApp extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      tableHead: ['Имя', 'Фамилия', 'Возраст', 'Город', 'Индекс', 'Телефон'],
      page: 1,
      rowsPerPage: 50,
      sort: {
        columnIndex: 0,
        sortDirection: 1
      },
      filterVal: '',
      loadingDataStatus: true
    };

    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleClearFilter = this.handleClearFilter.bind(this);

  }

  componentDidMount() {
    fetch("http://www.filltext.com?rows=520&firstName={firstName}&lastName={lastName}&age={numberRange|18,70}&city={city}&zip={zip}&phone={phone|format}")
      .then(res => res.json())
      .then(
        (result) => {

          let tableData = [];

          result.map((rowData, index) => {

            tableData.push(Object.values(rowData));

          });

          this.setState({...this.state, tableData: tableData, loadingDataStatus: false});

        },
        (error) => {
          alert('Ошибка при загрузке данных таблицы :(');
        }
      )
  }

  handleFilterChange(event) {

    const newStateData = {...this.state, filterVal: event.target.value, page: 1};

    this.setState(newStateData);

  }

  handleClearFilter(event) {

    const newStateData = {...this.state, filterVal: '', page: 1};

    this.setState(newStateData);

  }

  handlePaginationClick(page) {

    if (page === parseInt(page, 10) && page !== this.state.page) {

      const newStateData = {...this.state, page: page};

      this.setState(newStateData);

    }

  }

  handleSortClick(sortColumnIndex) {

    let currentSortColumnIndex = this.state.sort.columnIndex,
          currentSortDirection = this.state.sort.sortDirection;

    if (currentSortColumnIndex === sortColumnIndex) currentSortDirection = (-1)*currentSortDirection;
    else currentSortDirection = 1;

    const newStateData = {...this.state, page: 1, sort: {columnIndex: sortColumnIndex, sortDirection: currentSortDirection}};

    this.setState(newStateData);

  }

  render() {

    const tableData = this.state.tableData,
          loadingDataStatus = this.state.loadingDataStatus,
          tableHeadData = this.state.tableHead,
          filterVal = this.state.filterVal,
          currentPage = this.state.page,
          rowsPerPage = this.state.rowsPerPage,
          filteredTableData = filterTableData(tableData, filterVal),
          pagesCount = Math.ceil(filteredTableData.length/rowsPerPage),
          currentSortColumnIndex = this.state.sort.columnIndex,
          currentSortDirection = this.state.sort.sortDirection;


    const sortedTableDataOnPage = filteredTableData.sort((a, b) => {

      if (a[currentSortColumnIndex] > b[currentSortColumnIndex]) return currentSortDirection;
      else if (a[currentSortColumnIndex] < b[currentSortColumnIndex]) return -1*currentSortDirection;

      return 0;

    });

    const filteredTableDataOnPage = sortedTableDataOnPage.slice((currentPage-1)*rowsPerPage, currentPage*rowsPerPage);

    const rows = filteredTableDataOnPage.map((rowData, index) => {
      return (
        <TableRow
          key={index}
          tableData={rowData}
          currentSortColumnIndex={currentSortColumnIndex}
        />
      );
    });

    return (
      <div className="app">
        <div className="app-container">

          <Filter
            filterVal={filterVal}
            onChange={this.handleFilterChange}
            onClearClick={this.handleClearFilter}
          />

          <Pagination
            currentPage={currentPage}
            pagesCount={pagesCount}
            onClick={i => this.handlePaginationClick(i)}
          />

          <table className="table">
            <tbody>
              <TableHeadRow
                tableHeadData={tableHeadData}
                pagesCount={pagesCount}
                onClick={i => this.handleSortClick(i)}
                currentSortColumnIndex={currentSortColumnIndex}
                currentSortDirection={currentSortDirection}
              />
              {rows}
            </tbody>
          </table>

          <NoFilterResultsMessage
            pagesCount={pagesCount}
            loadingDataStatus={loadingDataStatus}
          />

        </div>

      </div>
    );
  }
}

export default TableApp;