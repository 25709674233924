import React from 'react';

function NoFilterResultsMessage(props) {

  if (props.loadingDataStatus === true) {

    return (
      <div className="loading">Идет загрузка данных. Подождите немного...</div>
    );

  }
  else if (props.pagesCount === 0) {

    return (
      <div className="no-results">Поиск не дал результатов. Совпадений не найдено</div>
    );

  }
  else return ('');

}

export default NoFilterResultsMessage;