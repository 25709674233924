import React from 'react';

function TableRow(props) {

  const rowCols = props.tableData.map((rowVal, index) => {

    let ceilClassName = 'table-ceil';

    if (props.currentSortColumnIndex === index) ceilClassName += ' sort';

    return (
      <td
        key={index}
        className={ceilClassName}
      >
        {rowVal}
      </td>

    );
  });

  return (
    <tr>
      {rowCols}
    </tr>
  );

}

export default TableRow;